import { useMemo } from 'react';
import { BodyMedium } from '../../../components/Text';
import LoadingLeaf from '../../../components/LoadingLeaf';
import { Experience } from '../../../services/receipts/types';
import SignupProvider, { useSignup } from './context';
import PhoneNumber from './screens/PhoneNumber';
import ConfirmationCode from './screens/ConfirmationCode';
import ConfirmationCodeManualEntry from './screens/ConfirmationCodeManualEntry';
import Password from './screens/Password';
import Email from './screens/Email';
import Name from './screens/Name';
import OrganizationName from './screens/OrganizationName';

const Signup = () => {
    const { screen, errorMessage } = useSignup();

    const signupRendered = useMemo(() => {
        switch (screen) {
            case 'PhoneNumber':
                return <PhoneNumber />;
            case 'Name':
                return <Name />;
            case 'OrganizationName':
                return <OrganizationName />;
            case 'ConfirmationCode':
                return <ConfirmationCode />;
            case 'ConfirmationCodeManualEntry':
                return <ConfirmationCodeManualEntry />;
            case 'Password':
                return <Password />;
            case 'Email':
                return <Email />;
            case 'Loading':
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 20,
                        }}
                    >
                        <LoadingLeaf />
                    </div>
                );
            default:
                return <></>;
        }
    }, [screen]);

    return (
        <>
            {signupRendered}
            {errorMessage ? (
                <BodyMedium
                    style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: 20,
                    }}
                >
                    {errorMessage || 'Sorry, something went wong'}
                </BodyMedium>
            ) : null}
        </>
    );
};

const WithProvider = ({
    goNext,
    shareToken,
    organizationToken,
    experience,
    manualCodeEntry,
    requestMobileChange,
}: {
    goNext: (amount?: number) => void;
    shareToken?: string;
    organizationToken?: string;
    experience?: Experience;
    manualCodeEntry?: boolean;
    requestMobileChange?: boolean;
}) => (
    <SignupProvider
        goNext={goNext}
        shareToken={shareToken}
        organizationToken={organizationToken}
        experience={experience}
        manualCodeEntry={manualCodeEntry}
        requestMobileChange={requestMobileChange}
    >
        <Signup />
    </SignupProvider>
);

export default WithProvider;
