import { lazy } from 'react';
import {
    AnimatedSectionItem,
    LandingPageConfig,
    VariationConfig,
} from './components/LandingPageVariations/configuration/types';
import Video from './components/Video';
import AmazonReconcile from './assets/landing/amazonReconcile.svg';
import MileageTracking from './assets/landing/mileageTracking.svg';
import { MetricsShowcase } from './components/Onboarding/MetricsShowcase';
import { SectionAnimation } from './components/LandingPageVariations/configuration/Variation';
import { CTAAlignmentWrapper } from './components/LandingPageVariations/elements/styles';
import { PreSignupSurveyScreen } from './screens/Onboarding/PreSignupSurvey/context';
import MetricsImage from './assets/landing/metric.svg';
import TeamTexting from './assets/landing/team_text.png';

// you might want to use this if you're making changes to a page that isn't the landing page, but is part of the same cohort
// you'd get the landingPageName from localStorage, then get activeCohort with useExperiment(landingPageName)
export const getVariation = (
    landingPageName: string,
    variationName?: string
) => {
    const config = landingPageVariationsConfig.find(
        (config) => config.landingPageName === landingPageName
    );
    if (!config) return undefined;
    const variation = config.baseVariation;
    const abVariation = !variationName
        ? undefined
        : config.ABVariations?.[variationName];
    const v: VariationConfig = { ...variation, ...abVariation };
    return v;
};

const ConstructionLandingJumbotron = lazy(
    () => import('./assets/animations/svGator/ConstructionLandingJumbotron')
);

/* eslint-disable @typescript-eslint/no-unused-vars */
const BASE_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Scan',
        subheader: 'Scan paper receipts and our AI extracts the important info',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Import',
        subheader:
            'Automatically import email receipts from your email accounts',
        animation: SectionAnimation.EMAIL,
    },
    {
        header: 'Personalize',
        subheader: 'Make custom folders and subfolders that fit your needs',
        animation: SectionAnimation.FOLDERS,
    },
    {
        header: 'Run Reports',
        subheader:
            'Generate reports that make expense tracking and taxes a breeze.',
        animation: SectionAnimation.REPORTS,
    },
];

const CONSTRUCTION_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Track Every Expense',
        subheader:
            'Know exactly what you’re making (or losing) by project. Our A.I. organizes all your paper & email receipts and invoices for full cost transparency.',
        animation: SectionAnimation.CON_TRACK,
    },
    {
        header: 'Accurate Instant Bids',
        subheader:
            'Snap a photo to draft an estimate in seconds. Get links to live local prices for materials.',
        animation: SectionAnimation.CON_BIDS,
    },
    {
        header: 'Maximize Writeoffs',
        subheader:
            '1-click tax reports capture every deduction, so you never pay more than you owe.',
        animation: SectionAnimation.CON_WRITEOFFS,
    },
];

const TEAMS_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Collect employee receipts by text',
        subheader:
            'No more lost receipts. Your AI Receipt Admin collects receipts via text, ensuring 100% adoption.',
        animation: SectionAnimation.TEXTING,
    },
    {
        header: 'AI Admin performs magic',
        subheader:
            'Your Receipt Admin extracts the important info from every texted receipts.',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Run Reports',
        subheader: 'Generate reports that make expense tracking a breeze',
        animation: SectionAnimation.REPORTS,
    },
];

const TEXTING_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Collect employee receipts by text',
        subheader: 'Employees digitize receipts in seconds',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Run Reports',
        subheader: 'See receipts in real-time reports',
        animation: SectionAnimation.REPORTS,
    },
    {
        header: 'Sync with Quickbooks',
        subheader: 'Expenses are automatically sent to Quickbooks',
        animation: SectionAnimation.QUICKBOOKS,
    },
];

const SALESTAX_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Scan',
        subheader:
            'Scan paper receipts and our A.I. extracts the sales tax, along with other important info',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Import',
        subheader:
            'A.I. automatically detects email receipts from your inbox and parses the sales tax',
        animation: SectionAnimation.EMAIL,
    },
    {
        header: 'Personalize',
        subheader:
            'Make custom folders and subfolders that fit your needs. Our A.I. suggests folders for your expenses based on your behavior.',
        animation: SectionAnimation.FOLDERS,
    },
    {
        header: 'Run Sales Tax Reports',
        subheader:
            'Generate reports that make totalling your sales tax across expenses a breeze.',
        animation: SectionAnimation.REPORTS,
    },
];

const QUICKBOOKS_ANIMATED_SECTIONS: AnimatedSectionItem[] = [
    {
        header: 'Scan',
        subheader: 'Scan paper receipts and our AI extracts the important info',
        animation: SectionAnimation.OCR,
    },
    {
        header: 'Import',
        subheader:
            'Automatically import email receipts from your email accounts',
        animation: SectionAnimation.EMAIL,
    },
    {
        header: 'Personalize',
        subheader: 'Make custom folders and subfolders that fit your needs',
        animation: SectionAnimation.FOLDERS,
    },
    {
        header: 'Sync',
        subheader: 'Sync your receipts and expenses to Quickbooks',
        animation: SectionAnimation.REPORTS,
    },
];

export const BASE_VARIATION_1: VariationConfig = {
    header: 'Tame Receipt Chaos',
    description: 'Transform email and paper clutter into life-changing reports',
    heroCallToAction: {
        label: 'Start for free',
        action: 'StartForFreeManualCodeEntry',
    },
    tabCallToAction: {
        label: 'Start for free',
        action: 'StartForFreeManualCodeEntry',
    },
    finalCallToAction: {
        label: 'Start for free',
        action: 'StartForFreeManualCodeEntry',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviews: 10,
};

export const BASE_VARIATION_1_DL_CTA: VariationConfig = {
    header: 'Tame Receipt Chaos',
    description: 'Transform email and paper clutter into life-changing reports',
    heroCallToAction: {
        label: 'Start for free',
        action: 'DownloadPage',
    },
    tabCallToAction: {
        label: 'Start for free',
        action: 'DownloadPage',
    },
    finalCallToAction: {
        label: 'Start for free',
        action: 'DownloadPage',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviews: 10,
};

export const TEAMS_VARIATION_1: VariationConfig = {
    header: 'Stop chasing people for receipts',
    description:
        'No more manual tracking. Organize business expenses in seconds.',
    heroCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    tabCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    finalCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviewsVertical: {
        title: 'Join 400,000+ Small Businesses',
        reviews: [
            {
                quote: `Saved us so much time and headache... it’s so easy that my guys actually remember to ‘submit’ their receipts now.\nBest app I have found for small business expense management. I recommend it to everyone I know.`,
                user: `- David, David’s Flooring of FL`,
            },
        ],
    },
};

export const TEAMS_VARIATION_2: VariationConfig = {
    header: '#1 Receipt App for Construction',
    description:
        'Stop chasing people for receipts! No more manual tracking. Organize business expenses in seconds.',
    heroCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    tabCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    finalCallToAction: {
        label: 'Get Started',
        action: 'StartTeams',
    },
    primaryVisualComp: (
        // @ts-ignore
        <Video
            src={require('./assets/animations/landing-page-jumbotron-v1.mp4')}
        />
    ),
    // pricing: {}, // see SectionPricing.tsx
    secondaryVisualComp: (
        <CTAAlignmentWrapper>
            <MetricsShowcase mode={'reduced'} style={{ maxWidth: 400 }} />
        </CTAAlignmentWrapper>
    ),
    reviewsVertical: {
        title: 'Join 400,000+ Small Businesses',
        reviews: [
            {
                quote: `Saved us so much time and headache... it’s so easy that my guys actually remember to ‘submit’ their receipts now.\nBest app I have found for small business expense management. I recommend it to everyone I know.`,
                user: `- David, David’s Flooring of FL`,
            },
        ],
    },
};

const LANDING_SUSPENSE_VIDEO = (
    // @ts-ignore
    <Video src={require('./assets/video/landingSuspense.mp4')} />
);

enum EXP_NAME {
    'welcome' = 'welcome',
    'may-16-30and90-demo-report' = 'may-16-30and90-demo-report',
    'may-5-30and90-baseline' = 'may-5-30and90-baseline',
    'may-5-30and90-manual-entry' = 'may-5-30and90-manual-entry',
    'may-01-send-signup-link' = 'may-01-send-signup-link',
    'may-5-declutter-value-prop' = 'may-5-declutter-value-prop',
    'may-5-salestax-value-prop' = 'may-5-salestax-value-prop',
    'jul-03-quickbooks-flow' = 'jul-03-quickbooks-flow',
    'jun-30-redirect-app-flow' = 'jun-30-redirect-app-flow',
    'Aug-18-redirect-with-cta' = 'Aug-18-redirect-with-cta',
    'july-19-sales-tax' = 'july-19-sales-tax',
    'july-28-redirect-branch' = 'july-28-redirect-branch',
    'sep-25-amazon-transaction' = 'sep-25-amazon-transaction',
    'sep-27-customized-referral-flow' = 'sep-27-customized-referral-flow',
    'oct-18-mileage' = 'oct-18-mileage',
    'oct-18-amazon-reconciliation' = 'oct-18-amazon-reconciliation',
    'jan-24-teams' = 'jan-24-teams',
    'mar-24-teams-construction' = 'mar-24-teams-construction',
    'mar-29-teams-typeform' = 'mar-29-teams-typeform',
    'collect-text-receipts' = 'collect-text-receipts',
    'cost-estimator' = 'cost-estimator',
    'welcome-bidcap-aug-14' = 'welcome-bidcap-aug-14',
    'welcome-bundle-aug-15' = 'welcome-bundle-aug-15',
}

export const EXP_NAME_TO_CONFIG: {
    [key in EXP_NAME]: {
        expName: string;
        baseVariation: VariationConfig;
        ABVariations?: {
            [key: string]: Partial<VariationConfig>;
        };
        branchAppStoreUrl?: string;
        branchPlayStoreUrl?: string;
    };
} = {
    // This is the '/' route!!
    'welcome': {
        expName: 'welcome',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: BASE_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                personalName: 'Personal',
                businessName: 'Business',
                teamsName: 'Teams',
                personalPrice: '7-days free, then $29.99/year',
                businessPrice: '7-days free, then $89.99/year',
                teamsPrice: '$89.99/year/employee',
                iconsOn: true,
                personalFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                ],
                businessFeatures: [
                    'Everything in personal',
                    'Track expenses by category',
                    'Export itemized expenses',
                    'Use across multiple devices',
                ],
                teamsFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                    'View employee receipts in real-time reports',
                    'Sync to Quickbooks',
                ],
            },
            headerMode: 'login',
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Digitize and organize your receipts using A.I. Generate reports instantly.',
            },
        },
    },
    'jan-24-teams': {
        expName: 'jan-24-teams',
        baseVariation: {
            ...TEAMS_VARIATION_1,
            sections: TEAMS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                teamsName: 'Teams',
                teamsPrice: '$89.99/year/employee',
                hidePersonal: true,
                hideBusiness: true,
                iconsOn: true,
            },
            headerMode: 'login',
        },
        ABVariations: {
            VariantBaseline: {},
        },
    },
    'may-5-30and90-baseline': {
        expName: 'may-5-30and90-baseline',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Tame Receipt Chaos',
            },
        },
    },
    'jul-03-quickbooks-flow': {
        expName: 'jul-03-quickbooks-flow',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: QUICKBOOKS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
        },
        ABVariations: {
            VariantTameChaos: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Easily sync your receipts to Quickbooks. No more receipt chaos. Save time and money at tax time.',
            },
        },
    },
    'may-5-declutter-value-prop': {
        expName: 'Aug-9-short-quiz-only',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Eliminate Receipt Chaos',
            description:
                'Digitize and organize your receipts using A.I. Generate reports instantly to simplify tax time.',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
    },
    'may-16-30and90-demo-report': {
        expName: 'may-16-30and90-demo-report',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                onboardingIntroductions: true,
                onboardingIntroductionReport: true,
                directPaywall: true,
            },
        },
    },
    'may-5-30and90-manual-entry': {
        expName: 'may-5-30and90-manual-entry',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Tame Receipt Chaos',
            },
            VariantSkipEnabled: {
                heroCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                tabCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                finalCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
            },
        },
    },
    'jun-30-redirect-app-flow': {
        expName: 'jun-30-redirect-app-flow',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: BASE_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            pricing: {
                personalName: 'Personal',
                businessName: 'Business',
                personalPrice: '7-days free, then $29.99/year',
                businessPrice: '7-days free, then $89.99/year',
                iconsOn: true,
                personalFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                ],
                businessFeatures: [
                    'Everything in personal',
                    'Track expenses by category',
                    'Export itemized expenses',
                    'Use across multiple devices',
                ],
            },
        },
        ABVariations: {
            VariantTameChaos: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Digitize and organize receipts with A.I. Generate reports instantly and simplify tax time',
            },
        },
    },
    'may-5-salestax-value-prop': {
        expName: 'may-29-salestax-entry',
        baseVariation: {
            ...BASE_VARIATION_1,
            sections: SALESTAX_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Instant Sales Tax Calculator',
                description:
                    'Total up sales tax from paper and email receipts instantly.',
            },
            VariantSkipEnabled: {
                heroCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                tabCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
                finalCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFreeManualCodeEntry',
                },
            },
        },
    },
    'may-01-send-signup-link': {
        expName: 'may-01-send-signup-link',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                heroCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFree',
                },
                tabCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFree',
                },
                finalCallToAction: {
                    label: 'Start for free',
                    action: 'StartForFree',
                },
            },
        },
    },
    'Aug-18-redirect-with-cta': {
        expName: 'Aug-18-redirect-with-cta',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Eliminate Receipt Chaos',
            description:
                'Digitize and organize your receipts using A.I. Generate reports instantly to simplify tax time.',
            heroCallToAction: {
                label: 'Get The App',
                action: 'DownloadPage',
            },
            tabCallToAction: {
                label: 'Get The App',
                action: 'DownloadPage',
            },
            finalCallToAction: {
                label: 'Get The App',
                action: 'DownloadPage',
            },
            reviews: 10,
        },
        ABVariations: {},
    },
    'july-19-sales-tax': {
        expName: 'july-19-sales-tax',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                onboardingPresignupInitialScreen: PreSignupSurveyScreen.Usage,
                onboardingUsageSurveryDisplayQuestion:
                    'What are you itemizing deductions for?',
                onboardingPresignupExtendedSurvey: true,
                onboardingShowRecommendedPlan: false,
            },
        },
    },
    'july-28-redirect-branch': {
        expName: 'july-28-redirect-branch',
        baseVariation: {
            ...BASE_VARIATION_1,
            redirectBranch: true,
        },
    },
    'sep-25-amazon-transaction': {
        expName: 'sep-28-amazon-transaction',
        baseVariation: {
            ...BASE_VARIATION_1,
        },
        ABVariations: {
            VariantBaseline: {
                redirectUrl:
                    'https://www.simplywise.com/blog/what-is-the-charge-for-amazon-mktplace-pmts-amzn-com-bill-wa-baseline/',
            },
            VariantTitleFraud: {
                redirectUrl:
                    'https://www.simplywise.com/blog/what-is-the-charge-for-amazon-mktplace-pmts-amzn-com-bill-wa-titlefraud/',
            },
            VariantImageFraud: {
                redirectUrl:
                    'https://www.simplywise.com/blog/what-is-the-charge-for-amazon-mktplace-pmts-amzn-com-bill-wa-fraudimage/',
            },
        },
    },
    'sep-27-customized-referral-flow': {
        expName: 'sep-27-customized-referral-flow',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: (searchParams) =>
                searchParams.get('ref_name') &&
                searchParams.get('ref_name')?.length
                    ? `${searchParams.get(
                          'ref_name'
                      )} Invites you to use SimplyWise!`
                    : 'Eliminate Receipt Chaos',
            description:
                'Digitize and organize your receipts using A.I. Generate reports instantly to simplify tax time.',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
    },
    'oct-18-mileage': {
        expName: 'oct-18-mileage',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: <img src={MileageTracking} alt="" />,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Track Mileage and Expenses',
            description: 'Everything for taxes, all in one place',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
        branchAppStoreUrl:
            'https://apps.apple.com/us/app/simplywise-receipt-scanner/id1538521095?ppid=1f4cac98-5024-40e6-96c1-2c140a9aa6dd',
        branchPlayStoreUrl:
            'https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=mileage-tracking-1',
    },
    'oct-18-amazon-reconciliation': {
        expName: 'oct-18-amazon-reconciliation',
        baseVariation: {
            ...BASE_VARIATION_1,
            primaryVisualComp: <img src={AmazonReconcile} alt="" />,
            onboardingPresignupExtendedSurvey: false,
            onboardingShowRecommendedPlan: false,
            header: 'Easy Expense Reconciling',
            description:
                'No more mystery charges. Organize your expenses in seconds.',
        },
        ABVariations: {
            VariantGetAppCTAShortQuiz: {
                onboardingPresignupExtendedSurvey: false,
                onboardingShowRecommendedPlan: false,
                heroCallToAction: {
                    label: 'Get The App',
                },
                tabCallToAction: {
                    label: 'Get The App',
                },
                finalCallToAction: {
                    label: 'Get The App',
                },
            },
        },
    },
    'mar-29-teams-typeform': {
        expName: 'mar-29-teams-typeform',
        baseVariation: {
            ...TEAMS_VARIATION_1,
            sections: TEAMS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                teamsName: 'Teams',
                teamsPrice: '$89.99/year/employee',
                hidePersonal: true,
                hideBusiness: true,
                iconsOn: true,
            },
            headerMode: 'login',
            tabCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            heroCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            finalCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            pricingCallToAction: {
                action: 'TeamsTypeform',
            },
        },
        ABVariations: {
            VariantBaseline: {},
        },
    },
    'mar-24-teams-construction': {
        expName: 'mar-24-teams-construction',
        baseVariation: {
            ...TEAMS_VARIATION_2,
            sections: TEAMS_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                teamsName: 'Teams',
                teamsPrice: '$89.99/year/employee',
                hidePersonal: true,
                hideBusiness: true,
                iconsOn: true,
            },
            headerMode: 'login',
            tabCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            heroCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            finalCallToAction: {
                label: 'Get Started',
                action: 'TeamsTypeform',
            },
            pricingCallToAction: {
                action: 'TeamsTypeform',
            },
        },
        ABVariations: {
            VariantBaseline: {},
        },
    },
    'collect-text-receipts': {
        expName: 'collect-text-receipts',
        baseVariation: {
            header: 'Easy Team \n Receipt Collection',
            primaryVisualComp: (
                <img
                    src={TeamTexting}
                    style={{
                        objectFit: 'contain',
                    }}
                />
            ),
            description:
                'Save hours every week. The #1 AI-powered \n Receipt Admin for teams',
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            tabCallToAction: {
                label: 'Get Started',
                action: 'TeamsCollectionSignup',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'TeamsCollectionSignup',
            },
            pricingCallToAction: {
                action: 'TeamsCollectionSignup',
            },
            sections: TEAMS_ANIMATED_SECTIONS,
            sectionsTitle: 'Stop Chasing Receipts',
            reviewsVertical: {
                title: 'Zero learning curve. Employees love it',
                reviews: [
                    {
                        quote: `First time ever that the guys submitted 100% of our monthly receipts 😮`,
                        user: `- Mikec67`,
                    },
                ],
            },
            singlePricing: {
                price: '$7.50 / User / Month',
                bulletPoints: [
                    'Scan & digitize unlimited receipts',
                    'Instantly turn receipts into spreadsheets',
                    'Instantly reconcile scanned receipts with bank & credit card statements',
                ],
            },
            finalCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'TeamsCollectionSignup',
            },
            finalCallToActionHeader: {
                text: 'Get Started',
                type: 'TextElement',
                render: true,
            },
        },
        ABVariations: {
            VariantBaseline: {},
            VariantEnterEmail: {},
        },
    },
    'cost-estimator': {
        expName: 'cost-estimator',
        baseVariation: {
            header: 'Instant construction \nestimates',
            description:
                'Take a photo. Describe the project. Instantly get an estimate. 100% free',
            primaryVisualComp: (
                // @ts-ignore
                <Video
                    src={require('./assets/video/CostEstimation.mp4')}
                    width={280}
                    style={{
                        width: 280,
                        borderRadius: 20,
                        marginLeft: 20,
                    }}
                />
            ),
            // pricing: {}, // see SectionPricing.tsx
            secondaryVisualComp: (
                <CTAAlignmentWrapper>
                    <img src={MetricsImage} alt="" />
                </CTAAlignmentWrapper>
            ),
            sections: [],
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
            finalCallToAction: undefined,
            tabCallToAction: {
                label: 'Get Started',
                action: 'CostEstimation',
            },
            heroCallToAction: {
                label: 'Get Started',
                sublabel: "It's free",
                action: 'CostEstimation',
            },
        },
        ABVariations: {
            VariantBaseline: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'CostEstimation',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'CostEstimation',
                },
            },
            VariantDownloadDirect: {
                tabCallToAction: {
                    label: 'Get Started',
                    action: 'CostEstimationDirectToApp',
                },
                heroCallToAction: {
                    label: 'Get Started',
                    sublabel: "It's free",
                    action: 'CostEstimationDirectToApp',
                },
            },
        },
    },
    'welcome-bidcap-aug-14': {
        expName: 'welcome-bidcap-aug-14',
        baseVariation: {
            ...BASE_VARIATION_1_DL_CTA,
            sections: BASE_ANIMATED_SECTIONS,
            primaryVisualComp: LANDING_SUSPENSE_VIDEO,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            pricing: {
                personalName: 'Personal',
                businessName: 'Business',
                teamsName: 'Teams',
                personalPrice: '7-days free, then $29.99/year',
                businessPrice: '7-days free, then $89.99/year',
                teamsPrice: '$89.99/year/employee',
                iconsOn: true,
                personalFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                ],
                businessFeatures: [
                    'Everything in personal',
                    'Track expenses by category',
                    'Export itemized expenses',
                    'Use across multiple devices',
                ],
                teamsFeatures: [
                    'Digitize paper receipts',
                    'Import email receipts',
                    'Unlimited storage and scans',
                    'View employee receipts in real-time reports',
                    'Sync to Quickbooks',
                ],
            },
            headerMode: 'signup',
        },
        ABVariations: {
            VariantBaseline: {
                header: 'Eliminate Receipt Chaos',
                description:
                    'Digitize and organize your receipts using A.I. Generate reports instantly.',
            },
        },
    },
    'welcome-bundle-aug-15': {
        expName: 'welcome-bundle-aug-15',
        baseVariation: {
            ...BASE_VARIATION_1_DL_CTA,
            header: 'Make every job profitable.',
            description:
                'Get accurate bids & track project costs in seconds. All in one place.',
            primaryVisualComp: <ConstructionLandingJumbotron />,
            sections: CONSTRUCTION_ANIMATED_SECTIONS,
            onboardingPresignupExtendedSurvey: true,
            onboardingShowRecommendedPlan: false,
            headerMode: 'signup',
        },
        ABVariations: {},
    },
};

export const landingPageVariationsConfig: LandingPageConfig[] = [
    {
        // This is the '/' route!!
        path: 'welcome',
        landingPageName: 'Welcome',
        ...EXP_NAME_TO_CONFIG['welcome'],
    },
    {
        path: 'google/emailonb',
        landingPageName: 'Google Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/pm',
        landingPageName: 'Google Pm Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/brex',
        landingPageName: 'Google Brex Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/gen_ex',
        landingPageName: 'Google GenEx Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'google/emailonb/gp',
        landingPageName: 'Google Gp Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'facebook/emailonb',
        landingPageName: 'Facebook Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'bing/emailonb',
        landingPageName: 'Bing Ads Welcome',
        ...EXP_NAME_TO_CONFIG['may-5-30and90-baseline'],
    },
    {
        path: 'facebook/demoreport',
        landingPageName: 'Facebook Report First Demo Report',
        ...EXP_NAME_TO_CONFIG['may-16-30and90-demo-report'],
    },
    {
        path: 'google/demoreport',
        landingPageName: 'Google Report First Demo Report',
        ...EXP_NAME_TO_CONFIG['may-16-30and90-demo-report'],
    },
    {
        path: 'google/salestax',
        landingPageName: 'Google Sales Tax Value Prop',
        ...EXP_NAME_TO_CONFIG['may-5-salestax-value-prop'],
    },
    {
        path: 'google/quickbooks',
        landingPageName: 'Google Quickbooks Flow',
        ...EXP_NAME_TO_CONFIG['jul-03-quickbooks-flow'],
    },
    {
        path: 'facebook/rocketship',
        landingPageName: 'Facebook declutter Value Prop',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/declutter',
        landingPageName: 'Facebook declutter Value Prop',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/iosonly',
        landingPageName: 'Facebook Declutter Value Prop iOS only',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/inbeat',
        landingPageName: 'Facebook Inbeat iOS only',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/retarget',
        landingPageName: 'Facebook Retarget',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'all/sales-tax',
        landingPageName: 'Sales Tax',
        ...EXP_NAME_TO_CONFIG['july-19-sales-tax'],
    },
    {
        path: 'ref',
        landingPageName: 'Referral Flow',
        ...EXP_NAME_TO_CONFIG['sep-27-customized-referral-flow'],
    },
    {
        path: 'facebook/iosonlybizopt',
        landingPageName: 'Facebook iOS only Biz Optimized',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'facebook/fernando',
        landingPageName: 'Influencer Redirect Branch Example',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'facebook/fernando-direct-to-app',
        landingPageName: 'Influencer Redirect Branch Example',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'facebook/retarget-registered-non-payers',
        landingPageName: 'Facebook Retarget',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'facebook/retarget-how-to-videos',
        landingPageName: 'Facebook Retarget what if videos',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'google/pmax-retarget',
        landingPageName: 'Google Pmax Retarget',
        ...EXP_NAME_TO_CONFIG['Aug-18-redirect-with-cta'],
    },
    {
        path: 'google/pmax',
        landingPageName: 'Google Pmax Landing Page',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'google/youtube',
        landingPageName: 'Google Pmax Landing Page',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'blog/return-policy',
        landingPageName: 'SimplyWise Blog Redirect Page',
        ...EXP_NAME_TO_CONFIG['may-5-declutter-value-prop'],
    },
    {
        path: 'google/amazon-transaction',
        landingPageName: 'SimplyWise Amazon Transaction',
        ...EXP_NAME_TO_CONFIG['sep-25-amazon-transaction'],
    },
    {
        path: 'mileage',
        landingPageName: 'SimplyWise Mileage',
        ...EXP_NAME_TO_CONFIG['oct-18-mileage'],
    },
    {
        path: 'amazon-reconciliation',
        landingPageName: 'SimplyWise Amazon Reconciliation',
        ...EXP_NAME_TO_CONFIG['oct-18-amazon-reconciliation'],
    },
    {
        path: 'google/teams',
        landingPageName: 'SimplyWise Teams',
        ...EXP_NAME_TO_CONFIG['jan-24-teams'],
    },
    {
        path: 'facebook/teams',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['jan-24-teams'],
    },
    {
        path: 'linkedin/teams',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['jan-24-teams'],
    },
    {
        path: 'facebook/teams-tf',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['mar-29-teams-typeform'],
    },
    {
        path: 'email/teams-tf',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['mar-29-teams-typeform'],
    },
    {
        path: 'email/teams/construction',
        landingPageName: 'SimplyWise teams',
        ...EXP_NAME_TO_CONFIG['mar-24-teams-construction'],
    },
    {
        path: 'google/whats-that-charge-retarget',
        landingPageName: 'SimplyWise Amazon Reconciliation',
        ...EXP_NAME_TO_CONFIG['oct-18-amazon-reconciliation'],
    },
    {
        path: 'facebook/whats-that-charge-retarget',
        landingPageName: 'SimplyWise Amazon Reconciliation',
        ...EXP_NAME_TO_CONFIG['oct-18-amazon-reconciliation'],
    },
    {
        path: 'linkedin',
        landingPageName: 'Welcome',
        ...EXP_NAME_TO_CONFIG['welcome'],
    },
    {
        path: 'facebook/teams-texting',
        landingPageName: 'Text Receipt Collection Aug 1',
        ...EXP_NAME_TO_CONFIG['collect-text-receipts'],
    },
    {
        path: 'google/teams-texting',
        landingPageName: 'Text Receipt Collection Aug 1',
        ...EXP_NAME_TO_CONFIG['collect-text-receipts'],
    },
    {
        path: 'userbrain/teams-texting',
        landingPageName: 'Text Receipt Collection Userbrain',
        ...EXP_NAME_TO_CONFIG['collect-text-receipts'],
    },
    {
        path: 'facebook/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'google/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'userbrain/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'reddit/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'email/cost-estimator',
        landingPageName: 'Cost Estimator',
        ...EXP_NAME_TO_CONFIG['cost-estimator'],
    },
    {
        path: 'facebook/bidcap',
        landingPageName: 'Welcome Bidcap',
        ...EXP_NAME_TO_CONFIG['welcome-bidcap-aug-14'],
    },
    {
        path: 'facebook/bundle',
        landingPageName: 'Welcome Bundle',
        ...EXP_NAME_TO_CONFIG['welcome-bundle-aug-15'],
    },
];
