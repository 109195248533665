import parsePhoneNumberFromString from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useAmplitude } from '../../../providers/AmplitudeProvider';
import AuthService from '../../../services/auth/service';
import gtagEvents from '../../../tracking/gtagEvents';
import identify from '../../../tracking/identify';
import {
    onWebAppSignupUrlAccessed,
    onWebAppSubmitCodeFailure,
    onWebAppSubmitCodeSuccess,
} from '../../../tracking/trackers';
import { useQuery } from '../../../util/hooks';
import * as Validators from '../../../util/validators';

const useReceiveVerificationParams = (
    setMobileAndCode: (m: string, c: string) => void,
    shareToken?: string,
    organizationToken?: string
) => {
    const query = useQuery();
    const code = query.get('code');
    const mobile = query.get('mobile');
    const originWebUrl = query.get('origin_web_url');
    const manualCodeEntry = query.get('manualCodeEntry');
    const isManualCodeEntry = manualCodeEntry === 'true';

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { isInitialized } = useAmplitude();

    const formattedMobile =
        parsePhoneNumberFromString(mobile || '', 'US')?.format(
            Validators.US_PHONE
        ) || '';

    useEffect(() => {
        if (isInitialized && formattedMobile) {
            identify(formattedMobile);
        }
    }, [formattedMobile, isInitialized]);

    useEffect(() => {
        if (code && formattedMobile) {
            if (!isManualCodeEntry) {
                onWebAppSignupUrlAccessed({
                    mobile: formattedMobile,
                    origin_web_url: originWebUrl,
                });
            }
            submitCode(code);
        } else {
            setIsLoading(false);
        }
    }, [code, formattedMobile, isManualCodeEntry]);

    const submitCode = (code: string) => {
        const tokenExists = !!localStorage.getItem('token');
        setIsLoading(true);
        setErrorMessage('');

        AuthService.verifyAccount(
            formattedMobile,
            code,
            shareToken,
            organizationToken
        )
            .then(() => {
                onWebAppSubmitCodeSuccess({
                    mobile: formattedMobile,
                    share_token: shareToken,
                    organization_token: organizationToken,
                });
                setMobileAndCode(formattedMobile, code);
                setIsConfirmed(true);
                // SIGNUP EVENTS
                ReactPixel.track('CompleteRegistration');
                gtagEvents.signup();
            })
            .catch(() => {
                // it might just attempt to submit code after a refresh, in which case an error is expected
                // if the token exists we know it was a refresh and won't handle an error
                if (!tokenExists) {
                    // for dev, to bypass code entry when connected to staging
                    if (code === '000000') {
                        setMobileAndCode(formattedMobile, code);
                        setIsConfirmed(true);
                        return;
                    }
                    onWebAppSubmitCodeFailure({ mobile: formattedMobile });
                    console.error('failure submitting code');
                    setErrorMessage('Failure submitting code');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return { errorMessage, isLoading, isConfirmed };
};

export default useReceiveVerificationParams;
