import { useEffect, useState } from 'react';
import { Container, AppBar, Toolbar, Typography } from '@mui/material';
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';
import './style.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useConnectEmail from '../Onboarding/useConnectEmail';
import EReceiptsService from '../../services/ereceipts/service';
import { useQuery } from '../../util/hooks';
import LogoIcon from '../../assets/landing/logo_icon.svg';
import LogoBlue from '../../assets/landing/logo_blue.svg';
import { HeaderContainer } from '../Landing/styles';
import { SectionHeader, SectionSubtitle } from '../../components/Text';
import { PageContainer } from '../Landing/styles';
import featuredMetrics2 from '../../assets/onboarding/featuredMetrics2_updated.svg';
import work from '../../assets/taxSavings/work.svg';
import email from '../../assets/taxSavings/email.svg';
import { TextInput } from '../../components/Inputs/styles';
import {
    IDeductionReceipt,
    IDeductionReport,
} from '../../services/ereceipts/types';
import {
    onWebAppTaxSavingsChecking,
    onWebAppTaxSavingsContinueToAccountSetup,
    onWebAppTaxSavingsDone,
    onWebAppTaxSavingsEnteredIndustry,
    onWebAppTaxSavingsGoogleClicked,
    onWebAppTaxSavingsGoogleConnected,
    onWebAppTaxSavingsImporting,
    onWebAppTaxSavingsStart,
} from '../../tracking/trackers';
import { useAuth } from '../../providers/AuthProvider';
import navigationActions from '../../util/navigationActions';
import GmailMockTaxDeduction from '../../assets/animations/svGator/GmailMockTaxDeduction';
import TaxDeductionLoading from '../../assets/animations/svGator/TaxDeductionLoading';
import Signup from '../Onboarding/Signup';
import { Experience } from '../../services/receipts/types';
import ReceiptRow from './ReceiptRow';

const BlurredComponent = styled.div<{ isBlurred: boolean }>`
    filter: ${(props) => (props.isBlurred ? 'blur(8px)' : undefined)};
`;

const AMOUNT_TO_SHOW = 3;

const TaxSavingsLanding = () => {
    const navigate = useNavigate();
    const { height } = useWindowDimensions();
    const query = useQuery();

    const { setToken } = useAuth();

    const oauthParams = query.get('$deeplink_path');
    const data = JSON.parse(oauthParams?.split('data=')[1] || '{}');
    const oauthToken = data?.token;

    let defaultStep = 0;
    if (oauthToken) {
        defaultStep = 3;
    }

    const [currentStep, setCurrentStep] = useState<number>(defaultStep);
    const [report, setReport] = useState<IDeductionReport | null>(null);

    const { startGoogleOauth } = useConnectEmail(
        () => {},
        'api/v2/auth/oauth/google/login',
        {
            source: 'tax-savings',
        },
        [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/gmail.readonly',
        ],
        Experience.TAX_SAVINGS
    );

    const STEPS = [
        'home',
        'industry',
        'connect',
        'importing',
        'checking',
        'done',
        'signup',
    ];

    const stepName = STEPS[currentStep];

    const navigateToStep = (step: string) => {
        setCurrentStep(STEPS.findIndex((s) => s === step));
    };

    useEffect(() => {
        if (oauthToken) {
            setToken(oauthToken);

            googleConnected();
        }
    }, [oauthToken]);

    const googleConnected = () => {
        onWebAppTaxSavingsGoogleConnected();

        navigateToStep('importing');

        EReceiptsService.createTaxSavingsReport().then((response) => {
            let i = 0;

            let intervalId = setInterval(() => {
                EReceiptsService.getTaxSavingsReport(response.id).then(
                    (response_status) => {
                        i++;

                        setReport(response_status.report);

                        if (!response_status.report) {
                            return;
                        }

                        if (
                            response_status.report.state ===
                            'importing_receipts'
                        ) {
                            navigateToStep('importing');
                        }

                        if (
                            response_status.report.state ===
                            'checking_deductions'
                        ) {
                            navigateToStep('checking');
                        }

                        if (response_status.report.state === 'done') {
                            clearInterval(intervalId);

                            navigateToStep('done');
                        }

                        if (i === 200) {
                            clearInterval(intervalId);

                            navigateToStep('done');
                        }
                    }
                );
            }, 2000);
        });
    };

    useEffect(() => {
        if (stepName === 'home') {
            onWebAppTaxSavingsStart();
        }

        if (stepName === 'importing') {
            onWebAppTaxSavingsImporting();
        }

        if (stepName === 'checking') {
            onWebAppTaxSavingsChecking();
        }

        if (stepName === 'done') {
            if (!report || !report.results) {
                return;
            }

            const nb_receipts = report.results.reduce((total, receipt, _) => {
                if (receipt.deductible_amount > 0) {
                    return total + 1;
                }

                return total;
            }, 0);

            onWebAppTaxSavingsDone({
                nb_receipts,
            });
        }
    }, [stepName]);

    if (stepName === 'home') {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.colors.white,
                }}
            >
                <PageContainer
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <HeaderContainer
                        style={{
                            justifyContent: 'center',
                            backgroundColor: '#fff',
                            height: 60,
                        }}
                    >
                        <a href="/">
                            <img
                                src={LogoIcon}
                                alt=""
                                width={34}
                                height={34}
                                style={{ marginRight: 10 }}
                            />
                            <img
                                src={LogoBlue}
                                alt={'SimplyWise Logo'}
                                width={170}
                                height={28}
                                style={{ marginTop: 6 }}
                            />
                        </a>
                    </HeaderContainer>

                    <Container
                        style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            justifyContent: 'center',
                            minHeight: height - 60,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyItems: 'space-between',
                        }}
                    >
                        <SectionHeader
                            style={{
                                textAlign: 'center',
                                marginTop: 20,
                                marginBottom: 20,
                                marginLeft: 20,
                                marginRight: 20,
                                fontSize: '2rem',
                            }}
                        >
                            Find Deductions Instantly
                        </SectionHeader>

                        <SectionSubtitle
                            style={{
                                textAlign: 'center',
                                marginTop: 0,
                                marginBottom: 20,
                                marginLeft: 20,
                                marginRight: 20,
                                fontSize: 16,
                                fontWeight: '600',
                                color: theme.colors.blue80,
                            }}
                        >
                            Save money on taxes. Our A.I. finds deductions in
                            your email you be missing.
                        </SectionSubtitle>

                        <div
                            style={{
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={featuredMetrics2}
                                width="80%"
                                alt="Metrics Showcase"
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                backgroundColor: theme.colors.background,
                                borderRadius: 10,
                                overflow: 'auto',
                                margin: 30,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <GmailMockTaxDeduction />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                padding: 20,
                                backgroundColor: '#fff',
                            }}
                        >
                            <ButtonBase
                                style={{
                                    borderRadius: 10,
                                    padding: 10,
                                    backgroundColor: theme.colors.accent,
                                    width: '100%',
                                }}
                                onClick={() => navigateToStep('industry')}
                            >
                                <Typography
                                    style={{
                                        color: theme.colors.white,
                                        fontWeight: '600',
                                        fontSize: '1.25rem',
                                    }}
                                >
                                    Get Started - It's FREE
                                </Typography>
                            </ButtonBase>
                        </div>
                    </Container>
                </PageContainer>
            </div>
        );
    }

    let body = null;
    if (stepName === 'industry') {
        body = (
            <>
                <div
                    style={{
                        flex: 1,
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <img src={work} width="200" alt={''} />
                    </div>

                    <SectionHeader
                        style={{
                            textAlign: 'center',
                            marginTop: 30,
                            marginBottom: 30,
                            marginLeft: 20,
                            marginRight: 20,
                            fontSize: '1.25rem',
                        }}
                    >
                        What do you do for work?
                    </SectionHeader>

                    <TextInput
                        placeholder={'E.g. "construction"'}
                        type={'text'}
                        variant={'outlined'}
                        onChange={(e) =>
                            localStorage.setItem('sw_industry', e.target.value)
                        }
                        required={true}
                        style={{
                            width: '100%',
                            marginBottom: 10,
                            fontSize: '0.75rem',
                        }}
                        InputProps={{
                            style: {
                                borderRadius: 8,
                                height: '3em',
                            },
                        }}
                    />
                </div>

                <div
                    style={{
                        width: '100%',
                        backgroundColor: '#fff',
                    }}
                >
                    <ButtonBase
                        style={{
                            borderRadius: 10,
                            padding: 10,
                            backgroundColor: theme.colors.accent,
                            width: '100%',
                        }}
                        onClick={() => {
                            onWebAppTaxSavingsEnteredIndustry({
                                industry: `${localStorage.getItem(
                                    'sw_industry'
                                )}`,
                            });
                            navigateToStep('connect');
                        }}
                    >
                        <Typography
                            style={{
                                color: theme.colors.white,
                                fontWeight: '600',
                                fontSize: '1.25rem',
                            }}
                        >
                            Continue
                        </Typography>
                    </ButtonBase>
                </div>
            </>
        );
    } else if (stepName === 'connect') {
        body = (
            <>
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <img src={email} width="200" alt={''} />
                    </div>

                    <SectionHeader
                        style={{
                            textAlign: 'center',
                            marginTop: 30,
                            marginBottom: 30,
                            marginLeft: 20,
                            marginRight: 20,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                        }}
                    >
                        Connect your Gmail to proceed!
                    </SectionHeader>
                </div>

                <div
                    style={{
                        width: '100%',
                        backgroundColor: '#fff',
                    }}
                >
                    <ButtonBase
                        style={{
                            borderRadius: 10,
                            padding: 10,
                            backgroundColor: theme.colors.accent,
                            width: '100%',
                        }}
                        onClick={() => {
                            onWebAppTaxSavingsGoogleClicked();
                            startGoogleOauth();
                        }}
                    >
                        <Typography
                            style={{
                                color: theme.colors.white,
                                fontWeight: '600',
                                fontSize: '1.25rem',
                            }}
                        >
                            Continue
                        </Typography>
                    </ButtonBase>
                </div>
            </>
        );
    } else if (stepName === 'importing' || stepName === 'checking') {
        body = <TaxDeductionLoading />;
    } else if (stepName === 'done') {
        let total = 0;
        let receipts_deductible: IDeductionReceipt[] = [];
        let receipts_others: IDeductionReceipt[] = [];

        if (report && report.results) {
            report.results.forEach((receipt) => {
                total += receipt['deductible_amount'];

                if (receipt['deductible_amount'] > 0) {
                    receipts_deductible.push(receipt);
                } else {
                    receipts_others.push(receipt);
                }
            });
        }

        body = (
            <>
                {receipts_deductible.length > 0 ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: 20,
                                    fontWeight: '500',
                                }}
                            >
                                {`We found`}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 40,
                                    fontWeight: '700',
                                    color: theme.colors.secondary,
                                }}
                            >
                                {`$${total.toFixed(2)}`}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 20,
                                    fontWeight: '500',
                                }}
                            >
                                {`in possible deductions!`}
                            </Typography>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'left',
                                }}
                            >
                                {receipts_deductible
                                    .slice(0, AMOUNT_TO_SHOW + 1)
                                    .map((receipt, i) => {
                                        const isBlurred = i >= AMOUNT_TO_SHOW;
                                        return (
                                            <BlurredComponent
                                                isBlurred={isBlurred}
                                            >
                                                <ReceiptRow receipt={receipt} />
                                            </BlurredComponent>
                                        );
                                    })}
                            </div>

                            {/* {receipts_others.length > 0 ? (
                                <>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        {receipts_others.map((receipt) => (
                                            <ReceiptRow receipt={receipt} />
                                        ))}
                                    </div>
                                </>
                            ) : null} */}
                            <Typography
                                style={{
                                    fontSize: 20,
                                    fontWeight: '500',
                                    margin: 30,
                                    flex: 1,
                                    textAlign: 'center',
                                    color: theme.colors.blue60,
                                }}
                            >
                                {`+ ${
                                    receipts_deductible.length - AMOUNT_TO_SHOW
                                } more`}
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 20,
                                    fontWeight: '500',
                                    margin: 30,
                                    flex: 1,
                                    textAlign: 'center',
                                }}
                            >
                                Create an account to see your complete list
                            </Typography>
                        </div>
                    </>
                ) : (
                    <Typography
                        style={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            marginBottom: 30,
                            flex: 1,
                        }}
                    >
                        We could not find any deductions.
                    </Typography>
                )}

                <ButtonBase
                    style={{
                        borderRadius: 10,
                        padding: 10,
                        backgroundColor: theme.colors.accent,
                        width: '100%',
                    }}
                    onClick={() => {
                        onWebAppTaxSavingsContinueToAccountSetup();
                        navigateToStep('signup');
                    }}
                >
                    <Typography
                        style={{
                            color: theme.colors.white,
                            fontWeight: '600',
                            fontSize: '1.25rem',
                        }}
                    >
                        Continue
                    </Typography>
                </ButtonBase>
            </>
        );
    } else if (stepName === 'signup') {
        body = (
            <Signup
                goNext={() => {
                    const { url, options } =
                        navigationActions.taxSavingsSignup();
                    navigate(url, options);
                }}
                experience={Experience.TAX_SAVINGS}
                manualCodeEntry
                requestMobileChange
            />
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <PageContainer
                style={{
                    maxWidth: 600,
                    backgroundColor: theme.colors.blue100,
                }}
            >
                <AppBar
                    position="static"
                    sx={{
                        bgcolor: theme.colors.primary,
                        color: '#ffffff',
                        textAlign: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        boxShadow: 'none',
                        height: 60,
                    }}
                >
                    <Toolbar>
                        <Typography variant="h6" component="div">
                            SimplyWise
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div
                    style={{
                        backgroundColor: '#fff',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 20,
                        minHeight: height - 60,
                    }}
                >
                    {body}
                </div>
            </PageContainer>
        </div>
    );
};

export default TaxSavingsLanding;
