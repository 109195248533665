import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../util/hooks';
import { useAuth } from '../../../providers/AuthProvider';
import { Experience } from '../../../services/receipts/types';
import useReceiveVerificationParams from './useReceiveVerificationParams';
import useSendCode from './useSendCode';
import useSetPassword from './useSetPassword';

export enum SignupScreen {
    PhoneNumber = 'PhoneNumber',
    Name = 'Name',
    OrganizationName = 'OrganizationName',
    ConfirmationCode = 'ConfirmationCode',
    ConfirmationCodeManualEntry = 'ConfirmationCodeManualEntry',
    Password = 'Password',
    Loading = 'Loading',
    Email = 'Email',
}

export interface ISignupProviderValue {
    errorMessage: string;
    isLoading: boolean;
    screen: SignupScreen;
    setScreen: React.Dispatch<React.SetStateAction<SignupScreen>>;
    mobile: string;
    setMobile: React.Dispatch<React.SetStateAction<string>>;
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    variant: string | null;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    code: string | undefined;
    setPassword: (password: string | undefined) => void;
    sendCodeToMobile: (mobileInput: string) => void;
    shareToken?: string;
    organizationToken?: string;
}

const SignupContext = createContext<ISignupProviderValue>({
    errorMessage: '',
    isLoading: false,
    screen: SignupScreen.Loading,
    setScreen: () => {},
    mobile: '',
    setMobile: () => {},
    name: '',
    setName: () => {},
    variant: null,
    email: '',
    setEmail: () => {},
    code: undefined,
    setPassword: () => {},
    sendCodeToMobile: () => {},
    shareToken: undefined,
    organizationToken: undefined,
});

const SignupProvider = ({
    children,
    goNext,
    shareToken,
    organizationToken,
    experience,
    manualCodeEntry: _manualCodeEntry,
    requestMobileChange,
}: {
    children: React.ReactNode;
    goNext: (amount?: number) => void;
    shareToken?: string;
    organizationToken?: string;
    experience?: Experience;
    manualCodeEntry?: boolean;
    requestMobileChange?: boolean;
}) => {
    const query = useQuery();
    const { setDidSignUp } = useAuth();
    const navigate = useNavigate();
    const manualCodeEntry = query.get('manualCodeEntry');
    const isManualCodeEntry = _manualCodeEntry || manualCodeEntry === 'true';
    const [variant, setVariant] = useState<string | null>(null);

    const [mobile, setMobile] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [code, setCode] = useState<string | undefined>(undefined);
    const [screen, setScreen] = useState<SignupScreen>(SignupScreen.Loading);

    useEffect(() => {
        setVariant((v) => {
            if (v) return v;
            return query.get('variant');
        });
    }, []);

    const setMobileAndCode = (m: string, c: string) => {
        setMobile(m);
        setCode(c);
    };

    const {
        errorMessage: sendSignupLinkError,
        isLoading: linkIsSending,
        codeIsSent,
        sendCodeToMobile,
    } = useSendCode(
        (m: string) => setMobile(m),
        isManualCodeEntry,
        name,
        shareToken,
        organizationToken,
        experience,
        email,
        requestMobileChange
    );
    const {
        errorMessage: receiveSignupLinkError,
        isLoading: codeConfirmIsLoading,
        isConfirmed: codeIsConfirmed, // once confirmed, take them to password screen
    } = useReceiveVerificationParams(
        setMobileAndCode,
        shareToken,
        organizationToken
    );
    const {
        errorMessage: setPasswordError,
        isLoading: setPasswordIsLoading,
        passwordIsSet,
        setPassword,
    } = useSetPassword(mobile, code);

    const isLoading =
        linkIsSending || codeConfirmIsLoading || setPasswordIsLoading;
    const errorMessage =
        sendSignupLinkError || setPasswordError || receiveSignupLinkError;

    useEffect(() => {
        if (isLoading) {
            setScreen(SignupScreen.Loading);
        } else if (passwordIsSet) {
            if (variant === 'organization') {
                // OrganizationService.createOrganization(name)
                //     .then(() => {
                //         goNext();
                //     })
                //     .catch(() => {
                //         goNext();
                //     });
                goNext();
            } else if (variant === 'texting') {
                navigate('/texting?onboarding=true');
            } else {
                goNext();
            }
        } else if (codeIsConfirmed && mobile) {
            setDidSignUp(true);
            setScreen(SignupScreen.Password);
        } else if (codeIsSent) {
            // Logic branches for whether it's a manual code entry or a link, but the confirmation logic is the same (useReceiveVerificationParams)
            if (isManualCodeEntry) {
                setScreen(SignupScreen.ConfirmationCodeManualEntry);
            } else {
                setScreen(SignupScreen.ConfirmationCode);
            }
        } else {
            if (variant === 'texting') {
                setScreen(SignupScreen.Email);
            } else if (variant === 'organization') {
                setScreen(SignupScreen.PhoneNumber);
            } else {
                setScreen(SignupScreen.PhoneNumber);
            }
        }
    }, [codeIsConfirmed, isLoading, codeIsSent, passwordIsSet]);

    return (
        <SignupContext.Provider
            value={{
                mobile,
                setMobile,
                name,
                setName,
                email,
                setEmail,
                code,
                errorMessage,
                isLoading,
                screen,
                variant,
                setScreen,
                setPassword,
                sendCodeToMobile,
                shareToken,
                organizationToken,
            }}
        >
            {children}
        </SignupContext.Provider>
    );
};

export const useSignup = () => useContext(SignupContext);
export default SignupProvider;
